<template>
  <div class="index">
    <!-- 预约咨询记录列表 -->
    <div class="searchBtn">
      <div>
        <el-date-picker clearable size="small" v-model="searchInfo.startTime" type="date" placeholder="开始时间"
          value-format="yyyy-MM-dd 00:00:00" style="margin-right:10px" format="yyyy-MM-dd">
        </el-date-picker>
        <span>至</span>
        <el-date-picker clearable size="small" v-model="searchInfo.endTime" type="date" placeholder="结束时间"
          format="yyyy-MM-dd" value-format="yyyy-MM-dd 23:59:59">
        </el-date-picker>
      </div>
      <el-input placeholder="请输入姓名" v-model="searchInfo.nickname" size="small" clearable>
      </el-input>
      <el-select v-model="searchInfo.status" size="small" clearable placeholder="请选择状态">
          <el-option label="未开始" :value="1">
          </el-option>
          <el-option label="进行中" :value="2">
          </el-option>
          <el-option label="已结束" :value="3">
          </el-option>
          <el-option label="支付中" :value="4">
          </el-option>
          <el-option label="已取消" :value="5">
          </el-option>
        </el-select>
        <el-select v-if="userRole === 1" v-model="searchInfo.consultId" size="small" clearable placeholder="请选择咨询师">
                            <el-option :label="item.nickname" :value="item.id" v-for="item in TeacherData"
                                :key="item.id">
                            </el-option>
                        </el-select>
      <el-button type="primary" size="small" @click="search">搜索</el-button>
      <el-button type="primary" size="small" @click="deleteConsults">删除</el-button>
    </div>
    <div class="table">
      <template>
        <el-table :data="tableData" border style="width: 100%" @selection-change="handleSelectionChange">
          <el-table-column type="selection">
          </el-table-column>
          <el-table-column prop="id" label="序号"  width="80"  fixed>
          </el-table-column>
          <el-table-column prop="userId" label="用户ID"  width="80"  fixed>
          </el-table-column>
          <el-table-column prop="nickname" label="用户"  width="150" show-overflow-tooltip fixed>
          </el-table-column>
          <el-table-column prop="wechatName" width="150" show-overflow-tooltip label="微信昵称">
          </el-table-column>
          <el-table-column prop="openId" label="openID" width="280" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="consultName" width="150" show-overflow-tooltip label="咨询师">
          </el-table-column>
          <el-table-column prop="mode" label="咨询方式">
            <template slot-scope="scoped">
              <span v-if="scoped.row.mode === 1">电话</span>
              <span v-else-if="scoped.row.mode === 2">面谈</span>
              <span v-else-if="scoped.row.mode === 3">视频</span>
              <span v-else-if="scoped.row.mode === 4">其他</span>
            </template>
          </el-table-column>
          <el-table-column prop="createTime" label="预约时间" width="150">
            <template slot-scope="scoped">
              <span v-if="scoped.row.startTime && scoped.row.endTime">{{ scoped.row.startTime }} - {{ scoped.row.endTime
}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="date" label="预约日期" width="130" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="createTime" label="创建时间" width="200" show-overflow-tooltip>
          </el-table-column>
          <el-table-column label="状态">
            <template slot-scope="scope">
              <span v-if="scope.row.status === 1">未开始</span>
              <span v-if="scope.row.status === 2">进行中</span>
              <span v-if="scope.row.status === 3">已结束</span>
              <span v-if="scope.row.status === 4">未支付</span>
              <span v-if="scope.row.status === 5">已取消</span>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="130">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="toReport(scope.row.consultReportId)">咨询报告</el-button>
              <el-button v-if="scope.row.status === 1" type="text" size="small" @click="cancel(scope.row.id)">取消</el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </div>
    <div class="block">
      <el-pagination @current-change="handleCurrentChange" :current-page="paging.p" :page-size="paging.ps"
        layout="total, prev, pager, next, jumper" :total="paging.total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getAppointmentList, cancelConsult, deleteConsult } from '@/api/consultation/appointmentConsultationRecord'
import { getTeachersList } from '@/api/dangerWarning/crisisIntervention'
export default {
  data() {
    return {
      tableData: [],
      TeacherData: [], // 咨询师用户
      searchInfo: {
        nickname: null,
        startTime: null,
        endTime: null,
        consultId: null, // 咨询师id
        status: null,// 状态
      },
      selectList: [], // 用户选择的数据
      paging: {
        p: 1,
        ps: 20,
        total: 0
      },
      userRole: 4, // 1.管理员 4.咨询师
    }
  },
  methods: {
    handleSelectionChange(val) {
      this.selectList = val;
      // this.multipleSelection = val;
    },
    // 获取咨询师列表
    async getTeacherList() {
            const res = await getTeachersList();
            console.log(res);
            if (res.code === 200) {
                this.TeacherData = res.data;
            }
        },
    // 获取咨询列表
    async getList() {
      let info = { p: this.paging.p, ps: this.paging.ps, ...this.searchInfo }
      const res = await getAppointmentList(info);
      console.log(res);
      if (res.code === 200) {
        this.tableData = res.data.records;
        this.paging.total = res.data.total;
      }
    },
    // 点击搜索
    search() {
      this.paging.p = 1;
      this.getList();
    },
    // 点击分页
    handleCurrentChange(val) {
      this.paging.p = val;
      this.getList();
    },
    // 点击查看咨询报告
    toReport(val) {
      this.$router.push({
        path: '/home/appointmentReport',
        query: {
          id: val
        }
      })
    },
    // 取消
    cancel(val) {
      this.$prompt('请输入取消理由：', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(async ({ value }) => {
        let info = new FormData();
        info.append('reason',value)
        const res = await cancelConsult(val, info);
        console.log(res);
        if(res.code === 200) {
          this.$message({
            type: 'success',
            showClose: true,
            message: '取消成功!取消原因：' + value
          });
        } else {
          this.$message({
            type: 'info',
            showClose: true,
            message: '取消失败!' + res.msg
          });
        }
        this.getList();
        // this.$message({
        //   type: 'success',
        //   message: '取消理由：' + value
        // });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    },
    // 删除预约咨询
    deleteConsults() {
      
      if (this.selectList.length > 0) {
        this.$confirm('确认删除该预约?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then( async() => {
          let arr = [];
          this.selectList.forEach(item => {
          arr.push(item.id)
        })
        const res = await deleteConsult(JSON.stringify(arr));
        console.log(res);
        if(res.code === 200) {
          this.$message({
            type: 'success',
            showClose: true,
            message: '删除成功!'
          });
        } else {
          this.$message({
            type: 'error',
            showClose: true,
            message: '删除失败!' + res.msg
          });
        }
        this.getList();
        }).catch(() => {
          this.$message({
            type: 'info',
            showClose: true,
            message: '已取消删除'
          });
        });
        
      } else {
        this.$message({
          type: 'info',
          showClose: true,
          message: '请选择需要删除的数据！'
        });
      }

    }
  },
  created() {
    this.userRole = (JSON.parse(sessionStorage.getItem('userInfo'))).roleId
    this.getTeacherList();
    this.getList();
  }
}
</script>

<style lang="scss" scoped>
.index {
  padding: 20px;

  .table {
    margin-top: 20px;
  }
}
</style>