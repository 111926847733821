import request from '@/utils/request'
// 预约咨询

// 获取咨询列表
export function getAppointmentList(data) {
    return request({
      url: '/reserve/page',
      method: 'post',
      data
    });
}
// 取消预约咨询
export function cancelConsult(id,data) {
    return request({
      url: `/reserve/cancel/${id}`,
      method: 'post',
      data
    });
}
// 删除预约咨询
export function deleteConsult(data) {
    return request({
      url: '/reserve',
      method: 'delete',
      data
    });
}